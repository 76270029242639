table.blueTable {
    border: 1px solid #1C6EA4;
    background-color: #EEEEEE;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}
table.blueTable td, table.blueTable th {
    border: 1px solid #AAAAAA;
    padding: 3px 2px;
}
table.blueTable tbody td {
    font-size: 13px;
}
table.blueTable tr:nth-child(even) {
    background: #D0E4F5;
}
table.blueTable thead {
    background: #1EA417;
    background: -moz-linear-gradient(top, #56bb51 0%, #34ad2e 66%, #1EA417 100%);
    background: -webkit-linear-gradient(top, #56bb51 0%, #34ad2e 66%, #1EA417 100%);
    background: linear-gradient(to bottom, #56bb51 0%, #34ad2e 66%, #1EA417 100%);
    border-bottom: 2px solid #444444;
}
table.blueTable thead th {
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    border-left: 2px solid #D0E4F5;
}
table.blueTable thead th:first-child {
    border-left: none;
}

table.blueTable tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background: #D0E4F5;
    background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    border-top: 2px solid #444444;
}
table.blueTable tfoot td {
    font-size: 14px;
}
table.blueTable tfoot .links {
    text-align: right;
}
table.blueTable tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
}


ion-fab {
    contain: layout;
}

.ion-fab-label {
    position: absolute;
    top: -8px;
    right: 40px;

    color: white;
    background-color: rgba(0,0,0,0.7);
    line-height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
}

.leaflet-control-layers-toggle {
    display: none;
}