.login-background {
    background-image: url("./assets/img/olivos.jpg");
    background-size: cover;
    background-position: center;
    min-height: 100%;
}

.theme-omron .login-background {
    background-image: url("./assets/img/tech.jpg");
    background-size: cover;
    background-position: center;
    min-height: 100%;
}

.login-background-1 {
    background-image: url("./assets/img/tech.jpg");
    background-size: cover;
    background-position: center;
    min-height: 100%;
}

.register-background {
    background-image: url("./assets/img/riego.jpg");
    background-size: cover;
    background-position: center;
    min-height: 100%;
}


.creditCard {
    border-bottom: solid 1px #dedede
}

.overlayStriped {
    background: linear-gradient(45deg, #d6d6d6 25%, #ffffff 25%, #ffffff 50%, #d6d6d6 50%, #d6d6d6 75%, #ffffff 75%, #ffffff 100%);
    background-size: 56.57px 56.57px;
}

.fab-custom {
    transform: scale(1);
    transition: transform 0.2s;
}

.fab-custom.fab-custom-hidden {
    transform: scale(0);
}

.connection-status {
    width: 16px;
    height: 16px;
    background: #0ec254;
    border-radius: 128px;
    display: inline-block;
}

.connection-status-offline {
    background: #ff5b71;
}

.passwordIcon {
    font-size: 2rem !important;
    position: relative !important;
    top: 18px !important;
    margin: 0 auto !important;
}

.logo {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    background: rgba(255, 255, 255, 0.5);
    padding: 15px 15px 0 15px;
}

.scheduler-not-running {
    text-decoration: line-through;
}


.gauge {
    position: relative;
    border-radius: 50%/100% 100% 0 0;
    /*background-color: var(--color, #a22);*/
    overflow: hidden;

}

.gauge:before {
    content: "";
    display: block;
    padding-top: 50%;
    /* ratio of 2:1*/
}

.gauge .chart {
    overflow: hidden;
}

.gauge .mask {
    position: absolute;
    left: 20%;
    right: 20%;
    bottom: 0;
    top: 40%;
    background-color: #fff;
    border-radius: 50%/100% 100% 0 0;
}

.gauge .percentage {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: 0;
    right: -1px;
    /*background-color: var(--background, #aaa);*/
    /*transform:rotate(var(--rotation));*/
    transform-origin: bottom center;
    transition-duration: 600;
}

.gauge:hover {
    --rotation: 100deg;
}

.gauge .value {
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 100%;
    text-align: center;
}

.gauge .min {
    font-size: 16px;
    position: absolute;
    bottom: 0;
    left: 5%;
}

.gauge .max {
    font-size: 16px;
    position: absolute;
    bottom: 0;
    right: 5%;
}

.gallery-selected {
    position: absolute;
    display: inline;
    width: 100%;
    background: rgba(128, 128, 128, 0.2);
    height: 100%;
    text-align: center;
    padding: 40%;
    font-size: 30px;
    color: #ffffff;
}

.item-updated {
    animation: fadeOut ease-in-out 0.5s;
}

.payment-details-selected {
    font-weight: bold;
}


@keyframes fadeOut {
    0% {
        background: none;
    }

    50% {
        background: #1aa5c8;
    }

    100% {
        background: none;
    }
}

.fluid1 {
    stroke-dasharray: 10;
    animation: dash 20s linear infinite;
}

.fluid-inverse {
    animation: dash-inverse 20s linear infinite;

}

@keyframes dash {
    to {
        stroke-dashoffset: 1000;
    }
}

@keyframes dash-inverse {
    to {
        stroke-dashoffset: -1000;
    }
}

@keyframes hideshow {
    0% {
        opacity: 1;
    }

    10% {
        opacity: 1;
    }

    15% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

/* body{ 
    font-family:"ITC Avant Garde Gothic Std" !important;
    font-size:1.1em;
  } */
.weather-card-content {
    overflow-x: auto;
}

.weather-container {
    /* position: absolute; */
    display: inline-flex;
    align-items: center;

    justify-items: first baseline;
    column-width: 2rem;

}

.weather-item {
    padding-left: 20px;
    padding-right: 20px;
    width: 180px;
    text-align: center;
}

.plt-mobile .weather-item {

    padding-left: 5px;
    padding-right: 5px;
    width: 100px;
    text-align: center;
}

.btn-round {
    --border-radius: 60px;
    width: 80px;
    height: 80px;
}

.icons-pager {
    margin-top: 8px;
    margin-bottom: 4px;
}

.slide-item {
    width: 120px;
    height: 120px;
}

.slide-title {
    line-break: auto;
    width: 100%;
    height: 20px;
}


.cloudrtu-hmi rect {
    transition: height 1s, y 1s
}

.cloudrtu-hmi .spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-box: fill-box;
    transform-origin: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.collapse-collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}

.collapse-content {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;
}

.collapse-content.active {
    transform: scaleY(1);
}

.error-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-container-message {
    text-align: center;

}
.error-container-icon {
    font-size: 8em;
    margin: 0 auto;
    
}

body.scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
  }

  .swiper-slide {
    text-align: initial;
    font-size: initial;
  }

  .newsImage {
    max-width: 480px;
    margin: 0 auto;
    

  }

  .newsImageContainer {
    background: #0ec254;
  }

  .blur {
    background: rgba(255, 255, 255, 0.2); 
    backdrop-filter: blur(8px);
    height: 100vh;
    width: 50%;
  }

  [hidden] {
    display: none !important;
  }

  .no-padding {
    padding: 0;
    margin: 0;
  }

  .custom-table > tr {
    border-bottom: dashed 1px black;
    margin: 5px;
    

  }
  .custom-table > tr > td {
    padding: 5px;
    

  }
  .custom-table > tr > th {
    padding: 5px;
    

  }