
.animatedBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("http://twibbon.s3.amazonaws.com/238/63bb30c8-2649-465e-9df1-ab2f8e5f7ecc.jpg");
    background-repeat: repeat;
    background-position: 0 0;
    background-size: auto 100%;
  /*adjust s value for speed*/
    animation: animatedBackground 500s linear infinite;
  }
  
  @keyframes animatedBackground {
    from {
      background-position: 0 0;
    }
  /*use negative width if you want it to flow right to left else and positive for left to right*/
    to {
      background-position: -10000px 0;
    }
  }